.information-card {
    display: flex;
    align-items: center;
    .icon {
        margin-right: 20px;
        svg {
            fill: $informationFontColor;
            width: 41px;
            height: 41px;
            path {
                fill: $informationFontColor;
            }
        }
    }
    .text {
        color: $informationFontColor;
        font-size: 1.5rem;
        @media( max-width: 1000px ) {
            font-size: 1.1rem;
        }
    }
}

.contrast-wcag {
    .information-card {
        .text {
            color: $contrastWhite;
        }
        .icon {
            svg {
                path {
                    fill: $contrastWhite;
                }
            }
        }
    }
}