.editor-html {
    label {
        display: block;
        margin-bottom: 5px;
    }
    textarea {
        min-height: 250px !important;
        width: 100%;
        resize: none;
        border: 1px solid #d2d2d2;
        padding: 10px;
    }
    .language-html {
        min-height: 250px;
        outline: none;
    }
}