.container-table {
    position: relative;
    margin-top: 20px;
    .wrapper-table {
        @media( max-width: 1000px ) {
            max-width: 900px;
            width: 100%;
            overflow-x: auto;
        }
        .header {
            .row {
                display: flex;
                justify-content: space-between;
                width: 100%;
                .col {
                    width: 100%;
                    // max-width: 200px;
                    @media( max-width: 1000px ) {
                        width: 130px;
                        min-width: 130px;
                    }
                    &:first-of-type {
                        min-width: 120px;
                        width: 120px;
                    }
                    &:last-of-type {
                        .text {
                            p {
                                cursor: default;
                            }
                        }
                    }
                    .text {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 20px;
                        border-bottom: 1px solid $tableBorderColor;
                        p {
                            font-size: 1rem;
                            font-weight: bold;
                            color: $tablePrimaryFontColor;
                            cursor: pointer;
                            width: max-content;
                        }
                        svg {
                            fill: $tablePrimaryFontColor;
                            cursor: col-resize;
                        }
                    }
                    .filter {
                        margin: 20px 0 30px 0;
                        width: calc(100% - 30px);
                    }
                }
            }
        }
        .results {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
            .row {
                display: flex;
                justify-content: space-between;
                width: 100%;
                // background-color: $tableBackgroundColor;
                // border-bottom: 1px solid $tableBorderColor;
                margin-bottom: 20px;
                &:last-of-type {
                    margin-bottom: 0;
                }
                .col {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    border-bottom: 1px solid $tableBorderColor;
                    background-color: $tableBackgroundColor;
                    // max-width: 200px;
                    overflow-x: auto;
                    @media( max-width: 1000px ) {
                        width: 130px;
                        min-width: 130px;
                    }
                    &:first-of-type {
                        min-width: 120px;
                        width: 120px;
                    }
                    .text {
                        padding: 20px;
                        p {
                            font-size: 1rem;
                            color: $tableSecondaryFontColor;
                            font-weight: 500;
                        }
                    }
                    .actions-box {
                        display: flex;
                        align-items: center;
                        padding: 0 20px;
                        .action {
                            display: flex;
                            cursor: pointer;
                            border: 2px solid transparent;
                            margin-right: 18px;
                            &:last-of-type {
                                margin: 0;
                            }
                            &:focus-visible {
                                border-color: $tableFocusColor;
                            }
                            svg {
                                fill: $tablePrimaryFontColor;
                                path {
                                    fill: $tablePrimaryFontColor;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .opened-chats {
        position: fixed;
        width: auto;
        bottom: 0;
        right: 0;
        justify-content: flex-end;
        display: flex;
        column-gap: 20px;
    }
    ::-webkit-scrollbar {
        height: 0;
        width: 0;
    }
    ::-webkit-scrollbar-track {
        background: transparent; 
    }
    ::-webkit-scrollbar-thumb {
        background: transparent; 
    }
    ::-webkit-scrollbar-thumb:hover {
        background: transparent; 
    }
    ::-moz-selection {
        background: $scrollbar; 
    }
    ::selection {
        background: $scrollbar; 
    }
}

.contrast-wcag {
    .container-table {
        .wrapper-table {
            .header {
                .col {
                    .text {
                        border-color: $contrastWhite;
                        p {
                            color: $contrastWhite;
                        }
                    }
                }
            }
            .results {
                .col {
                    background-color: transparent;
                    border-color: $contrastWhite;
                    .text {
                        p {
                            color: $contrastWhite;
                        }
                    }
                    .actions-box {
                        .action {
                            &:focus-visible {
                                border-color: $contrastPrimary;
                            }
                            svg {
                                path {
                                    fill: $contrastWhite;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}