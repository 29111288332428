.new-page-container {
    .wrapper {
        display: flex;
        @media( max-width: 1000px ) {
            flex-direction: column;
        }
        .form {
            max-width: calc(75% - 20px);
            width: 100%;
            margin-right: 20px;
            @media( max-width: 1500px ) {
                max-width: calc(65% - 20px);
            }
            @media( max-width: 1000px ) {
                max-width: 100%;
            }
        }
        .boxes {
            max-width: 25%;
            width: 100%;
            @media( max-width: 1500px ) {
                max-width: 35%;
            }
            @media( max-width: 1000px ) {
                margin-top: 20px;
                flex-direction: column;
                max-width: 100%;
            }
            .btn-fix {
                position: fixed;
                bottom: 50%;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;transition: .3s;
                background-color: $primaryColor;
                cursor: pointer;
                border-radius: 5px 0 0 5px;
                transform: translateY(50%);
                border: 2px solid transparent;
                transition: .3s;
                &:focus-visible {
                    border-color: $contrastSecondary
                }
                &:hover {
                    transform: translateY(50%) scale(1.2);
                }
                svg {
                    height: 35px;
                    width: 35px;
                    // fill: $secondaryColor;
                }
            }
        }
        .input-row {
            margin-bottom: 15px;
            &:last-of-type {
                margin-bottom: 0;
            }
            .select-wrap {
                .select-wrapper {
                    max-width: 300px;
                }
            }
        }
    }
}