// .radio-wrapper {
//     margin-bottom: 15px;
//     &:last-of-type {
//         margin-bottom: 0;
//     }
//     label {
//         padding-left: 10px;
//     }
//     input {
//         transform: scale(1.6); 
//     }
// }

// .contrast-wcag {
//     .radio-wrapper {
//         label {
//             color: $contrastPrimary;
//         }
//     }
// }

.radio-wrapper {
    margin-bottom: 15px;
    .container-radio {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 45px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        min-height: 22px;
        &:focus-visible, &:focus-within {
            .label {
                border-color: $checkboxFocusColor;
            }
        }
        .label {
            border: 2px solid transparent;
            color: $checkboxFontColor;
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked {
                &~.checkmark {
                    &:after {
                        display: block;
                        background-color: $checkboxBorderColor;
                    }
                }
            }
            &:focus-visible {

            }
        }
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 22px;
            width: 22px;
            border: 2px solid $checkboxBorderColor;
            &:after {
                content: "";
                display: none;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                // display: none;
                width: 12px;
                height: 12px;
            }
        }
    }
}

.contrast-wcag {
    .radio-wrapper {
        .container-radio {
            &:focus-visible, &:focus-within {
                .label {
                    border-color: $contrastWhite;
                }
            }
            .label {
                border: 2px solid transparent;
                color: $contrastPrimary;
            }
            input {

                &:checked {
                    &~.checkmark {
                        &:after {
                            background-color: $contrastWhite;
                        }
                    }
                }
            }
            .checkmark {
                border: 2px solid $contrastWhite;
            }
        }
    }
}