.color-picker {
    label {
        display: block;
        margin: 8px 0;
        color: $editorLabelFontColor;
        font-weight: bold;
    }
    .color {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 15px;
        border: 1px solid #000000;
        cursor: pointer;
        &.active {
            border: 2px solid green;
        }
    }
}

.contrast-wcag {
    .color-picker {
        label {
            color: $contrastPrimary;
        }
    }
}