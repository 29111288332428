.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $popupBg;
    width: 100%;
    height: 100%;
    z-index: 999999999999;
}
.popup {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1000px;
    width: 100%;
    height: 450px;
    z-index: 9999999;
    overflow-y: auto;
    @media( max-width: 1050px ) {
        height: auto;
        max-width: 95vw;
        max-height: 95vh;
    }
    .popup-wrapper {
        position: relative;
        background-color: $popupContentBg;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .popup-header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 65px;
            background-color: $popupHeaderBg;
            padding: 0 60px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            @media( max-width: 1050px ) {
                padding: 0 30px;
            }
            span {
                color: $popupCloseFontColor;
                font-size: 1.4rem;
                cursor: pointer;
                padding: 5px;
                border: 2px solid transparent;
                outline: none;
                &:focus-visible {
                    border-color: $popupFocusColor;
                }
                p {
                    display: inline-block;
                    color: $popupCloseFontColor;
                    margin-left: 15px;
                    font-size: 1.6rem;
                }
            }
        }
        .popup-content {
            margin-top: 65px;
            height: calc(100% - 120px);
            padding: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            @media( max-width: 1050px ) {
                height: auto;
                padding: 30px;
            }
            .editor {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                max-width: 100%;
                outline: none;
                * {
                    color: $popupContentFontColor;
                }
                h1,h2 {
                    font-size: 2rem;
                    position: relative;
                    width: max-content;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                    display: block;
                    max-width: 100%;
                    @media( max-width: 1199px ) {
                        font-size: 2rem;
                    }
                    @media( max-width: 500px ) {
                        font-size: 1.6rem;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 1px;
                        width: 100%;
                        background-color: $popupContentFontColor;
                    }
                }
                p {
                    display: block;
                    max-width: 100%;
                    font-size: 1.2rem;
                    @media( max-width: 992px ) {
                        font-size: 0.9rem;
                    }
                    display: inline-block;
                    span {
                        color: $error;
                    }
                }
            }
            .btn-wrap {
                margin-top: 15px;
            }
        }
    }
}