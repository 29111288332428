.patterns {
    small,label {
        color: $secondaryColor;
    }
    label {
        display: block;
        margin-bottom: 8px;
        font-weight: bold;
    }
    .section-items {
        display: flex;
        flex-direction: row;
        // flex-wrap: wrap;
        overflow-y: auto;
        .patterns-content {
            border: 1px solid $secondaryColor;
            margin: 10px;
            max-height: calc(250px - 20px);
            max-width: calc(33.3% - 20px);
            min-width: 150px;
            width: 33.3%;
            height: 150px;
            &:last-of-type {
                margin-bottom: 0;
            }
            .section-index {
                margin: 0;
            }
            .images-box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                padding: 5px;
                img {
                    max-height: 130px;
                }
            }
        }
    }
}

.contrast-wcag {
    .patterns {
        small, label {
            color: $contrastWhite;
        }
        .section-items {
            .patterns-content {
                border-color: $contrastWhite;
            }
        }
    }
}