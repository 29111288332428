.menus-section {
    .welcome {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 30px;
        margin-bottom: 20px;
        border-bottom: 1px solid $menusBorderColor;
        @media( max-width: 450px ) {
            flex-direction: column;
        }
        h1 {
            color: $menusPrimaryFontColor;
            font-size: 2rem;
            font-weight: 500;
            @media( max-width: 450px ) {
                margin-bottom: 15px;
            }
        }
    }
    .menus-wrapper {
        display: flex;
        @media( max-width: 950px ) {
            flex-direction: column;
        }
        .col {
            &:nth-of-type(1) {
                max-width: 280px;
                width: 100%;
                margin-right: 60px;
                @media( max-width: 950px ) {
                    max-width: 100%;
                }
            }
            &:nth-of-type(2) {
                z-index: 999999;
                max-width: 100%;
                width: 100%;
                @media( max-width: 950px ) {
                    margin-top: 30px;
                }
            }
            .pages-types {
                // max-width: 280px;
                width: 100%;
                border-bottom: 3px solid $menusBorderColor;
                .type {
                    display: flex;
                    flex-direction: column;
                    &.active {
                        .pages {
                            padding: 15px 12px;
                            opacity: 1;
                            height: auto;
                            ul {
                                li {
                                    padding: 10px 0;
                                    opacity: 1;
                                    height: auto;
                                    color: $menusPrimaryFontColor;
                                    margin-bottom: 3px;
                                }
                            }
                        }
                    }
                    .header {
                        padding: 24px 20px 20px 20px;
                        background-color: $menusPrimaryBgColor;
                        background: #f0f0f0;
                        position: relative;
                        cursor: pointer;
                        border: 2px solid transparent;
                        border-bottom: 2px solid $menusBorderColor;
                        margin-top: 1px;
                        &:focus-visible {
                            border-color: $menusFocusColor;
                        }
                        &:hover {
                            background-color: $menusPrimaryBgColorHover;
                        }
                        .svg-icon {
                            position: absolute;
                            right: 25px;
                            top: 50%;
                            transform: rotate(-90deg) translateX(100%);
                            svg {
                                path {
                                    fill: $menusPrimaryFontColor;
                                }
                            }
                        }
                        span {
                            display: block;
                            font-weight: bold;
                            color: $menusPrimaryFontColor;
                            padding-right: 25px;
                        }
                    }
                    .pages {
                        height: 0;
                        opacity: 0;
                        background-color: $menusSecondaryBgColor;
                        ul {
                            padding: 0;
                            margin: 0;
                            li {
                                padding: 0;
                                font-size: 0.875rem;
                                cursor: pointer;
                                position: relative;
                                overflow: hidden;
                                height: 0;
                                opacity: 0;
                                border-bottom: 2px solid $menusBorderColor;
                                p {
                                    width: max-content;
                                    border: 2px solid transparent;
                                    &:focus-visible {
                                        border-color: $menusFocusColor;
                                    }
                                }
                            }
                        }
                        .action-btn-add {
                            display: flex;
                            justify-content: flex-end;
                        }
                    }
                }
            }
            .menus-list {
                display: flex;
                flex-direction: column;
                max-width: 100%;
                width: max-content;
                @media( max-width: 950px ) {
                    width: 100%;
                }
                .menus {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 -15px;
                    .menu-type {
                        border: 2px solid transparent;
                        margin: 0 15px 20px 15px;
                        &:focus-visible {
                            border-color: $menusFocusColor;
                        }
                        p {
                            display: flex;
                            align-items: center;
                            text-align: center;
                            padding: 15px 40px;
                            cursor: pointer;
                            transition: .3s;
                            color: $menusSecondaryFontColor;
                            border: 2px solid $menusBorderColor;
                            border-radius: 30px;
                            &:hover, &.active {
                                background: $menusButtonColorHover;
                                color: $menusTertiaryFontColor;
                            }
                        }
                        @media(max-width: 650px) {
                            padding: 15px;
                        }
                    }
                }
                .menu-items {
                }
                .btn-save {
                    margin-top: 10px;
                    margin-left: 7px;
                    width: 400px;
                    z-index: 99999999;
                    text-align: right;
                    &.more-margin {
                        margin-top: 20px;
                    }
                    @media( max-width: 700px ) {
                        width: 100%;
                        max-width: 250px;
                        min-width: 250px;
                    }
                }
            }
        }
    }
    .action-btn {
        font-size: 16px;
        cursor: pointer;
        position: relative;
        width: max-content;
        border: 2px solid transparent;
        &:focus-visible {
            border-color: $menusFocusColor;
        }
    }
    .remove {
        color: $error;
        &:before {
            content: '';
            position: absolute;
            bottom: -3px;
            right: -7px;
            width: calc(100% + 14px);
            height: 2px;
            background-color: $error;
        }
    }
    .add {
        color: $menusPrimaryFontColor;
        &:before {
            content: '';
            position: absolute;
            bottom: -3px;
            right: -7px;
            width: calc(100% + 14px);
            height: 2px;
            background-color: $menusBorderColor;
        }
    }
    label {
        font-size: 16px;
        padding: 0 3px;
        margin-bottom: 5px;
        position: relative;
        width: max-content;
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $menusBorderColor;
        }
    }
    .input-menu {
        padding: 10px 10px;
        border: 2px solid $menusBorderColor;
        z-index: 999999;
        outline: none;
        width: 100%;
        color: $menusPrimaryFontColor;
        &:focus-visible {
            border-color: $menusFocusColor !important;
        }
        &.error {
            border: 2px solid $error !important;
        }
    }
    .input-row {
        padding-bottom: 10px;
        label {
            display: block;
            margin-bottom: 5px;
            color: $menusSecondaryFontColor;
        }
    }
}

.contrast-wcag {
    .menus-section {
        .welcome {
            border-color: $contrastWhite;
            h1 {
                color: $contrastWhite;
            }
        }
        .input-row {
            label {
               &:before {
                   background-color: $contrastSecondary;
               } 
            }
        }
        .input-menu {
            border-color: $contrastSecondary;
            &:focus-visible {
                border-color: $contrastPrimary !important;
            }
        }
        .add {
            color: $contrastSecondary;
            &:before {
                background-color: $contrastSecondary;
            } 
        }
        .action-btn {
            &:focus-visible {
                border-color: $contrastSecondary;
            }
        }
        .menus-wrapper {
            .col {
                .pages-types {
                    border-bottom: none;
                    .type {
                        .header {
                            border-color: $contrastWhite;
                            background-color: $contrastWhite;
                            &:focus-visible {
                                border-color: $contrastPrimary;
                            }
                            span {
                                color: $contrastSecondary;
                            }
                            .svg-icon {
                                svg {
                                    path {
                                        fill: $contrastSecondary;
                                    }
                                }
                            }
                        }
                        .pages {
                            ul {
                                li {
                                    color: $contrastSecondary;
                                    border-bottom: 2px solid $contrastPrimary;
                                    &:focus-visible {
                                        border-color: $contrastPrimary;
                                    }
                                }
                            }
                        }
                    }
                }
                .menus-list {
                    .menus {
                        .menu-type {
                            &:focus-visible {
                                border-color: $contrastPrimary;
                            }
                            p {
                                border-color: $contrastWhite;
                                color: $primaryColor;
                                &.active, &:hover {
                                    background-color: $contrastWhite;
                                    color: $contrastSecondary;
                                }
                            }
                        }
                    }
                    .menu-items {
                        .page-item {
                            &.active {
                                .header-item {
                                    background-color: $contrastPrimary;
                                    span, small {
                                        color: $contrastSecondary;
                                    }
                                    .svg-icon {
                                        svg {
                                            path {
                                                fill: $contrastSecondary;
                                            }
                                        }
                                    }
                                }
                            }
                            .header-item {
                                border-color: $contrastPrimary;
                                &:focus-visible {
                                    border-color: $contrastWhite;
                                }
                                &:hover {
                                    background-color: $contrastPrimary;
                                    span, small {
                                        color: $contrastSecondary;
                                    }
                                    .svg-icon {
                                        svg {
                                            path {
                                                fill: $contrastSecondary;
                                            }
                                        }
                                    }
                                }
                                span, small {
                                    color: $contrastWhite;
                                }
                                .svg-icon {
                                    svg {
                                        path {
                                            fill: $contrastWhite;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

h1 {
    &:focus-visible {
        border: 2px solid green;
    }
}