.container-answers-pdf {
    // 800px psd
    span, p {
        color: #000000;
    }
    .header {
        display: flex;
        justify-content: space-between;
        // justify-content: center;
        // align-items: center;
        height: 75px;
        margin: 0 !important;
        .logo {
            height: 100%;
            width: 235px;
            padding: 5px 20px 5px 65px;
            position: relative;
            background-color: #ffffff;
            img {
                width: 140px;
                background-color: #ffffff;
            }
            span {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 140px;
                line-height: 100%;
                height: 100%;
                z-index: -1;
                // background-color: rgba(0,0,0,.5);
            }
        }
        .text {
            display: flex;
            align-items: center;
            background-color: #2c5299 !important;
            -webkit-print-color-adjust: exact !important;
            color-adjust: exact !important;
            width: calc(100vw - 235px);
            height: 100%;
            span {
                display: block;
                padding-left: 35px;
                text-transform: uppercase;
                color: #ffdb47;
                font-weight: bold;
                font-size: 20px;
            }
        }
    }
    .wrapper {
        padding: 0 65px;
        background-color: initial;
        .info {
            padding: 15px 0 30px 0; 
            span {
                color: #000000;
            }
        }
        .title {
            padding: 15px 0 35px 0; 
            span {
                color: #2c5299;
                font-weight: bold;
                font-size: 20px;
            }
        }
        .subtitle {
            padding-bottom: 20px;
            display: flex;
            span {
                color: #2c5299;
                font-weight: bold;
            }
            p {
                margin: 0 5px;
            }
        }
        .clinics {
            .clinic {
                padding-bottom: 20px;
                .title-wrapper {
                    margin-bottom: 5px;
                    span {
                        font-weight: bold;
                    }
                }
                .text-wrapper {

                }
            }
        }
        .text {
            padding-bottom: 40px;
            margin-bottom: 30px;
            border-bottom: 1px solid #e0e2e2;
            span {
                display: block;
                &.blue-open {
                    color: #2c5299;
                    margin-top: 5px;
                }
            }
        }
        .space {
            display: block;
            padding: 15px 0;
        }
        .test {
            padding: 0 0 0 0;
            .question {
                border-bottom: 1px solid #e0e2e2;
                padding-bottom: 10px;
                margin-bottom: 15px;
                span {
                    color: #000000;
                    width: 500px;
                    font-size: 18px;
                }
                .row-pdf {
                    display: flex;
                    justify-content: space-between;
                }
                .item-of-multiple {
                    display: block;
                    margin-top: 5px;
                }
                .short-text {
                    color: #2c5299;
                    text-transform: uppercase;
                    font-weight: bold;
                    width: auto;
                    margin-right: 30px;
                    margin-left: 15px;
                    text-align: right;
                }
                .blue-open {
                    display: block;
                    color: #2c5299;
                    padding-top: 20px;
                }
            }
        }
    }
    .hidden {
        opacity: 0;
        width: 0px;
        height: 0px;
        margin: 0px;
        padding: 0px;
    }
}

@page {
    size: A4;
    // margin: 30px 0;
    // margin: 0;
}
// .firefox {
//     @media print {
//         body {
//             display: table;
//             table-layout: fixed;
//             padding: 30px 0;
//             height: auto;
//         }
//         @page {
//             margin: 0;
//         }
//     }
// }

.firefox {
    display: table;
    table-layout: fixed;
    padding: 30px 0;
    height: auto;
}