.icon-desc {
    .section-items {
        .icon-desc-content {
            background-color: #ffffff;
            border: 1px solid $secondaryColor;
            padding: 50px 30px 20px 30px;
            position: relative;
            margin-bottom: 15px;
            &:first-of-type {
                margin-top: 30px;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
            .section-index {
                display: flex;
                align-items: center;
                position: absolute;
                right: 0;
                top: 10px;
                padding: 0 30px;
                @media( max-width: 600px ) {
                    top: 5px;
                    right: 0;
                    padding: 0 15px;
                }
                @media( max-width: 400px ) {
                    padding: 0 5px;
                }
                i, span {
                    color: $secondaryColor;
                    border: 2px solid transparent;
                    &.remove-item-icon {
                        cursor: pointer;
                    }
                    &:focus-visible {
                        border-color: $focusColor;
                    }
                }
                span {
                    margin-right: 5px;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
            .content {
                background-color: #ffffff;
                display: none;
                &.active {
                    display: block;
                }
            }
            .add-item-in-section {
                margin-top: 20px;
            }
            .row-section {
                margin-bottom: 15px;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
    .select-options, .select-boxes {
        margin-top: 8px;
    }
}

.contrast-wcag {
    .icon-desc {
        .section-items {
            .icon-desc-content {
                background-color: $contrastSecondary;
            }
        }
    }
}