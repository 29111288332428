.autotests-answer {
    position: relative;
    h2 {
        margin-top: 30px;
        margin-bottom: 50px;
        color: $secondaryColor;
    }

    hr {
        height:1px;
        border:none;
        color: $secondaryColor;
        background-color: $secondaryColor;
        margin-bottom: 45px;

    }
    .subtitle {
        margin-bottom: 10px;
        color: $secondaryColor;
    }
    .answers {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 0 20px;
        grid-template-areas:
            ". . . . .";
        @media( max-width: 1780px ) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-areas:
                ". . . .";
        }
        @media( max-width: 1480px ) {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas:
                ". . .";
        }
        @media( max-width: 880px ) {
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
                ". .";
        }
        @media( max-width: 640px ) {
            grid-template-columns: 1fr;
            grid-template-areas:
                ".";
        }
    }

    .reset-top-margin {
        margin-top: 0;
    }

    .opened-chats {
        position: fixed;
        width: auto;
        bottom: 0;
        right: 0;
        justify-content: flex-end;
        display: flex;
        column-gap: 20px;
    }

    .pagination-wrap {
        margin-bottom: 30px;
    }
}

.contrast-wcag {
    .chat-section {

        h2 {
            color: $contrastSecondary;
        }
    
        hr {
            color: $contrastSecondary;
            background-color: $contrastSecondary;
    
        }
        .subtitle {
            color: $contrastSecondary;
        }

    }
}