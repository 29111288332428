.notification {
    width: 200px;
    height: auto;
    padding: 20px 15px;
    background-color: $notiBgColor;
    border-radius: 30px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.27);
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.27);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.27);
    display: flex;
    position: fixed;
    flex-direction: column;
    bottom: 50px;
    right: 20px;
    transition: .2s linear all;
    transform: translateX(250px);
    z-index: 99999999;
    &.visible {
        transform: translateX(0);
    }

    h4 {
        font-weight: 700;
        margin-bottom: 10px;
    }
    p {
 
    }
    // .editor {
        h1,h2,h3,h4,h5,h6 {
            margin-bottom: 15px;
            color: $notiSecondaryFontColor;
        }
        p {
            margin-bottom: 10px;
        }
    // }
    * {
        color: $notiPrimaryFontColor;
    }
}