.media-library-container {
    .wrapper {
        position: relative;
        .action-inputs {
            display: flex;
            justify-content: space-between;
        }
        .table-media {
            position: relative;
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-top: 35px;
            .images-wrapper {
                display: flex;
                flex-wrap: wrap;
                .image-box {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;
                    max-width: calc(20% - 10px);
                    max-height: 200px;
                    height: 200px;
                    width: 100%;
                    border: 1px solid $mediaBorderColor;
                    padding: 20px;
                    margin: 5px;
                    overflow-y: auto;
                    position: relative;
                    &:focus-visible {
                        border: 2px solid $mediaPrimaryFocusColor;
                    }
                    @media( max-width: 1650px ) {
                        max-width: calc(25% - 10px)
                    }
                    @media( max-width: 1000px ) {
                        max-width: calc(33.3% - 10px);
                    }
                    @media( max-width: 750px ) {
                        max-width: calc(50% - 10px);
                    }
                    @media( max-width: 550px ) {
                        max-width: calc(100%);
                    }
                    &.png, &.svg {
                        img {
                            background-image: url('./../../../images/transparent-bg.jpg')
                        }
                    }
                    img {
                        max-height: 100px;
                        max-width: 100%;
                        height: auto;
                        width: 100%;
                        object-fit: contain;
                        cursor: pointer;
                        &.bg-svg {
                            background-image: url('./../../../images/transparent-bg.jpg')
                        }
                    }
                    .simple-info-image {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        margin-top: 10px;
                        cursor: pointer;
                        @media( max-width: 550px ) {
                            text-align: center;
                        }
                        .info-row {
                            margin-bottom: 5px;
                            @media( max-width: 1000px ) {
                                margin-bottom: 0;
                            }
                            &:last-of-type {
                                margin-bottom: 0;
                                border-bottom: none;
                                padding-bottom: 0;
                            }
                            span {
                                font-size: 1rem;
                                color: $mediaFontColor;
                                @media( max-width: 1000px ) {
                                    font-size: 0.9rem;
                                }
                                @media( max-width: 550px ) {
                                    font-size: 0.8rem;
                                }
                            }
                            .label {
                                font-weight: bold;
                            }
                        }
                    }
                    .remove-icon {
                        z-index: 999999;
                        & i {
                            color: $mediaLoaderFontColor;
                            cursor: pointer;
                            position: absolute;
                            top: 5px;
                            right: 5px;
                            z-index: 20;
                            border: 2px solid transparent;
                            &:focus-visible {
                                border-color: $mediaLoaderFocusColor;
                            }
                        }
                    }
                }
            }
        }
    }
}


.media-item-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $mediaPopupRgbaColor;
    max-width: 100vw;
    width: 100%;
    height: 100vh;
    z-index: 31;
    @media( max-width: 650px ) {
        flex-direction: column;
    }
    &.hidden {
        opacity: 0;
    }
    .image-box-popup {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 640px;
        width: 100%;
        max-height: 600px;
        height: 100%;
        padding: 30px;
        background-color: $mediaPopupBgColor;
        @media( max-width: 1000px ) {
            max-width: 300px;
            max-height: 400px;
        }
        @media( max-width: 650px ) {
            max-height: 150px;
            padding: 10px;
        }
        &.png, &.svg {
            background-image: url('./../../../images/transparent-bg.jpg')
        }
        img {
            max-width: 100%;
            max-height: 100%;
        }
        audio {
            outline: none !important;
        }
    }
    .information-img {
        padding: 30px;
        background-color: $mediaPopupBgColor;
        position: relative;
        max-width: 330px;
        max-height: 600px;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        @media( max-width: 1000px ) {
            max-width: 300px;
            max-height: 400px;
        }
        @media( max-width: 650px ) {
            max-height: 250px;
            padding: 10px;
        }
        .exit {
            display: flex;
            width: 15px;
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            user-select: none;
            z-index: 9999;
            padding: 5px;
            border: 2px solid transparent;
            margin: -7px;
            box-sizing: content-box;
            &:focus-visible {
                border-color: $mediaFocusColor;
            }
        }
        .info {
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;
            span {
                color: $mediaFontColor;
            }
            .label {
                font-weight: bold;
                margin-bottom: 8px;
            }
            &:last-of-type {
                padding-top: 10px;
            }
        }
    }
}

.media-upload-popup {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $mediaPopupRgbaColor;
    width: 100%;
    height: 100%;
    z-index: 999999999999;
    
    &.file {
        .popup {
            height: 90vh;
            max-width: 1280px;
        }
    }
}
.popup {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1000px;
    width: 100%;
    height: 600px;
    z-index: 9999999;
    overflow-y: auto;
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        background: transparent; 
    }
    ::-webkit-scrollbar-thumb {
        background: $secondaryColor; 
    }
    ::-webkit-scrollbar-thumb:hover {
        background: transparent; 
    }
    ::-moz-selection {
        background: $scrollbar; 
    }
    ::selection {
        background: $scrollbar; 
    }

    @media( max-width: 1050px ) {
        height: auto;
        max-width: 95vw;
        max-height: 95vh;
    }
    .popup-wrapper {
        position: relative;
        background-color: $popupContentBg;
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;

        .popup-header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 65px;
            background-color: $popupHeaderBg;
            padding: 0 60px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            @media( max-width: 1050px ) {
                padding: 0 30px;
            }
            span {
                color: $popupCloseFontColor;
                font-size: 1.4rem;
                cursor: pointer;
                padding: 5px;
                border: 2px solid transparent;
                outline: none;
                &:focus-visible {
                    border-color: $popupFocusColor;
                }
                p {
                    display: inline-block;
                    color: $popupCloseFontColor;
                    margin-left: 15px;
                    font-size: 1.6rem;
                }
            }
        }
        .popup-media-content {
            margin-top: 65px;
            padding: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-direction: column;
            margin-top: 85px;

            @media( max-width: 1050px ) {
                height: auto;
                padding: 30px;
            }
            .item {
                margin-bottom: 20px;
                width: 50%;

                @media( max-width: 960px ) {
                    width: 100%;
                }

                &:last-of-type {
                    margin-bottom: 0;
                    display: flex;
                    justify-content: center;
                }

                &.file {
                    width: 100%;
                    margin-top: 5px;
                }

                &.yt {
                    width: 100%;
                    margin-top: 5px;
                    .repeater {
                        grid-template-columns: 1fr 1fr; 

                        @media( max-width: 930px ) {
                            grid-template-columns: 1fr; 
                        }

                        @media( max-width: 600px ) {
                            grid-template-columns: 1fr; 
                        }
                    }
                }
              
                .btn-wrap {
                    width: 100%;
                    max-width: 250px;
                }
                .repeater {
                    display: grid; 
                    grid-template-columns: 1fr 1fr 1fr; 
                    gap: 20px 20px; 

                    @media( max-width: 930px ) {
                        grid-template-columns: 1fr 1fr; 
                        gap: 20px 20px;  
                    }

                    @media( max-width: 600px ) {
                        grid-template-columns: 1fr; 
                        gap: 20px 20px;  
                    }

                    .img {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 4px;
                        border: 1px solid $secondaryColor;
                        padding: 20px 15px;

                        .img-prev {
                            height: 150px;
                            width: 150px;
                            margin-bottom: 20px;
                            
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .yt-prev {
                            height: 150px;
                            width: 265px;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}

.contrast-wcag {

    .popup {
        ::-webkit-scrollbar-thumb {
            background: #000000; 
        }

        .popup-wrapper {
            background-color: #ffffff;
    
            .popup-header {
                background-color: $contrastSecondary;
            }

            .popup-media-content {

                .item {
    
                    .repeater {
    
                        .img {
                            border: 1px solid $contrastSecondary;
                        }
                    }
                }
            }
        }
    } 
}

.contrast-wcag {
    .media-library-container {
        .title {
            border-color: $contrastWhite;
            h2 {
                color: $contrastWhite;
            }
        }
        .action-inputs {

        }
    }
    .table-media {
        .images-wrapper {
            .image-box {
                border-color: $contrastWhite !important;
                &:focus-visible {
                    border-color: $contrastPrimary !important;
                }
                .simple-info-image {
                    .info-row {
                        span {
                            color: $contrastPrimary !important;
                        }
                    }
                }
                // &.png, &.svg {
                //     .simple-info-image {
                //         .info-row {
                //             span {
                //                 color: $contrastSecondary !important;
                //             }
                //         }
                //     }
                // }
            }
        }
    }
    .media-item-popup {
        .image-box-popup {
            background-color: $contrastSecondary;
        }
        .information-img {
            background-color: $contrastSecondary;
            .exit {
                &:focus-visible {
                    border-color: $contrastWhite;
                }
                svg {
                    fill: $contrastWhite;
                }
            }
            .info {
                span {
                    color: $contrastWhite;
                }
            }
        }
    }
}