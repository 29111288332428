@import './../../scss/settings/colors.scss';

.live-chat-wrap {
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 500px;
    background-color: #ffffff;
    position: relative;
    z-index: 40;

    &.close {
        transform: translateY(467px);
    }

    &.ended, &.not-my {
        .chat-body {
            height: calc(100% - 36px);
        }
    }
    
    .send-message {
        position: absolute;
        display: flex;
        bottom: 0;
        width: 100%;
        border: 2px solid $liveChatBackgroundColor;
        border-top: 0;
        
        form {
            width: 100%;

            textarea {
                width: 100%;
                height: 100%;
                min-height: 75px;
                padding: 10px;
                font-family: inherit;
            }
            .disabled {
                cursor: not-allowed;
            }
        }
        .send {
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: .2s ease-in-out all;
            margin: 0 5px;
            &:hover {
                i {
                    transform: scale(.95);
                }
            }
            i {
                transition: .2s ease-in-out all;
                color: $liveChatBackgroundColor;
                &:focus-visible {
                    border: 1px solid $primaryColor
                }
            }       
        }

    }

    .panel-heading {
        width: 100%;
        background-color: $liveChatBackgroundColor;
        color: #fff;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        .icon-chat {
            font-size: 20px;
        }

        .material-icons {

            &:focus-visible {
                border: 1px solid $primaryColor
            }
        }
    }

    .chat-body {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        // height: calc(100% - 75px - 36px);
        height: calc(100% - 82px - 36px);
        border: 2px solid $liveChatBackgroundColor;

        .message-wrap {
            background-color: #f0f0f0;
            width: 100%;
            padding: 10px;
            color: $liveChatTextColor;
            border-bottom: 1px solid #fdfdfd;

            &.me {
                background-color: #ffffff;

                .user {
                    font-weight: bold;
                }
            }
            .chat-user-message {
                .chat-message {
                    word-break: break-all;
                }
            }
        }

        
    }

    .hide-chat {
        cursor: pointer;

        i {
            margin-right: 10px;
        }
    }
    .close-chat {
        cursor: pointer;
    }
    .left-col {
        display: flex;

        span {
            opacity: .15;
            height: 25px;
            width: 1px;
            margin-right: 10px;
            display: block;
            background-color: #ffffff;
        }
    }
    .exit, .finish {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: .2s ease-in-out all;
        i {
            margin-right: 10px;
            transition: .2s ease-in-out all;
        }

       
    }

    .finish, .exit {

        &:hover {
            i {
                transform: scale(.95);
            }
        }
        
    }

    .chat-bubble {
        display: flex;
        align-items: center;
        cursor: pointer;

        i {
            margin-right: 10px;
        }
    }
}

.contrast-wcag {

    .live-chat-wrap {
        background-color: #ffffff;
        
        .send-message {
            position: absolute;
            display: flex;
            bottom: 0;
            width: 100%;
            border: 2px solid $contrastSecondary;
            border-top: 0;
            
            form {
                width: 100%;
    
                textarea {
                    width: 100%;
                    height: 100%;
                    min-height: 75px;
                    padding: 10px;
                    font-family: inherit;
                }
                .disabled {
                    cursor: not-allowed;
                }
            }
        }
    
        .panel-heading {
            background-color: $contrastSecondary;
        }
    
        .chat-body {
            border: 2px solid $contrastSecondary;
    
            .message-wrap {
                background-color:$contrastWhite;
                width: 100%;
                padding: 10px;
                color: $contrastSecondary;
                border-bottom: 1px solid #fdfdfd;
    
                &.me {
                    background-color: $contrastPrimary;
    
                    .user {
                        font-weight: bold;
                    }
                }
            }
    
            
        }
    
        .hide-chat {
            cursor: pointer;
    
            i {
                margin-right: 10px;
            }
        }
        .close-chat {
            cursor: pointer;
        }
        .left-col {
            display: flex;
    
            span {
                opacity: .15;
                height: 25px;
                width: 1px;
                margin-right: 10px;
                display: block;
                background-color: #ffffff;
            }
        }
        .exit, .finish {
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: .2s ease-in-out all;
            i {
                margin-right: 10px;
                transition: .2s ease-in-out all;
            }
    
           
        }
    
        .finish, .exit {
    
            &:hover {
                i {
                    transform: scale(.95);
                }
            }
            
        }
    
        .chat-bubble {
            display: flex;
            align-items: center;
            cursor: pointer;
    
            i {
                margin-right: 10px;
            }
        }
    }

}