.question-container {
    .wrapper {
        .form {
            .input-row {
                margin-bottom: 15px;
                .select-wrap {
                    max-width: 670px;
                }
            }
            .answers-box {
                .answers-items {
                    .answer-content {
                        position: relative;
                        border: 1px solid $secondaryColor;
                        padding: 50px 30px;
                        margin-bottom: 20px;
                        @media( max-width: 600px ) {
                            padding: 35px 20px;
                        }
                        &:last-of-type {
                            margin-bottom: 0;
                        }
                        .section-index {
                            position: absolute;
                            right: 30px;
                            top: 10px;
                            @media( max-width: 600px ) {
                                top: 5px;
                                right: 20px;
                            }
                            i {
                                color: $secondaryColor;
                                border: 2px solid transparent;
                                &.remove-item-icon {
                                    cursor: pointer;
                                }
                                &:focus-visible {
                                    border-color: $focusColor;
                                }
                            }
                        }
                    }
                }
            }
            .add-item-in-section {
                margin-top: 20px;
                display: flex;
                justify-content: flex-end;
            }
        }
        .save-btn {
            margin-top: 20px;
        }
    }
}

.contrast-wcag {
    .question-container {
        .wrapper {
            .form {
                .answers-box {
                    .answers-items {
                        .answer-content {
                            border-color: $contrastWhite;
                            .section-index {
                                i {
                                    color: $contrastPrimary;
                                    &:focus-visible {
                                        border-color: $contrastWhite;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}