.select-wrap {
    position: relative;

    label {
        color: $secondaryColor;
        font-weight: 700;
        margin-bottom: 10px;
        text-align: left;
        width: 100%;
        display: block;
        z-index: 10;
    }
    .select-wrapper {
        z-index: 11;
        border: 2px solid transparent;
        padding: 0 !important;
        &:focus-visible, &:focus-within {
            border-color: $btnFocusColor !important;
        }
    }
    .select {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        padding: 12px 18px 12px 25px;
        font-size: 1.125rem;
        font-weight: bold;
        // border: 2px solid transparent;
        border-radius: 30px;
        cursor: pointer;
        // transition: .3s;
        height: 47px;
        &.active {
            border-radius: 30px 30px 0 0;
            .list {
                display: block;
            }
        }
        &.btn-primary {
            background-color: $btnPrimaryBg;
            color: $btnPrimaryFont;
            &:hover {
                background-color: $btnPrimaryBgHover;
                .list {
                    background-color: $btnPrimaryBgHover;
                }
            }
            .list {
                background-color: $btnPrimaryBg;
                li {
                    color: $btnPrimaryFont;
                    &:focus-visible, &:hover {
                        color: $btnPrimaryFontHover;
                        border-color: $btnPrimaryFontHover
                    }
                }
            }
        }
        &.btn-secondary {
            background-color: $btnSecondaryBg;
            color: $btnSecondaryFont;
            &:hover {
                background-color: $btnSecondaryBgHover;
                color: $btnSecondaryFontHover;
                .list {
                    background-color: $btnSecondaryBgHover;
                    li {
                        color: $btnSecondaryFontHover;
                        &:focus-visible, &:hover {
                            color: $btnSecondaryFontHover;
                        }
                    }
                }
            }
            .list {
                background-color: $btnSecondaryBg;
                li {
                    color: $btnSecondaryFont;
                    &:focus-visible, &:hover {
                        color: $btnSecondaryFont;
                        border-color: $btnSecondaryFont
                    }
                }
            }
        }

        &.btn-simple {
            background-color: #ffffff;
            border: 1px solid $btnPrimaryBg;
            color: $btnSecondaryFont;
            justify-content: space-between;

            &:hover {
                background-color: #ffffff;
            }

            &.active {
                background-color: #ffffff;
                border-bottom-color: transparent;

                .list {
                    background-color: #ffffff;
                    border-top-color: transparent;
                }
            }
            .list {
                background-color: #ffffff;
                border: 1px solid $secondaryColor;

                li {
                    color: $secondaryColor;
                    &:hover {
                        color: $secondaryColor
                    }
                    &:focus-visible {
                        color: $fourColor;
                        border-color: $fourColor
                    }
                }
            }
            .selected {
                color:  #474747;
                font-weight: 400;
            }
            .arrow {
                svg {
                    path {
                        fill: #2c5299;
                    }
                }
            }
            
        }

        .selected {

        }
        .list {
            display: none;
            list-style: none;
            position: absolute;
            top: 45px;
            left: 0;
            right: 0;
            padding: 10px;
            margin: 0 auto;
            text-align: center;
            border-radius: 0 0 30px 30px;
            width: 100%;
            z-index: 11;
            // transition: .3s;
            li {
                margin: 0 auto 6px auto;
                width: max-content;
                border: 2px solid transparent;
                word-break: break-word;
                max-width: 100%;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .arrow {
            transform: rotate(-90deg);
            margin-left: 15px;
            transition: .3s;
            &.active {
                transform: rotate(90deg);
            }
            svg {
                path {
                    fill: #ffffff;
                }
            }
        }
    }
}

.contrast-wcag {
    .select-wrap {
        label {
            color: $contrastPrimary;
        }
        .select {
            &.btn-primary, &.btn-secondary {
                background-color: $contrastPrimary;
                color: $contrastSecondary;
                // &:hover {
                //     // background-color: $contrastWhite;
                //     .list {
                //         background-color: $contrastWhite;
                //         li {
                //             color: $contrastSecondary;
                //         }
                //     }
                // }
                .list {
                    background-color: $contrastPrimary;
                    li {
                        color: $contrastSecondary;
                        font-weight: normal;
                        &:focus-visible {
                            font-weight: bold;
                            border-color: $contrastSecondary
                        }
                    }
                }
                .arrow {
                    svg {
                        path {
                            fill: $contrastSecondary;
                        }
                    }
                }
            }
            &.btn-simple {
                border: none;
                .list {
                    li {
                        font-weight: normal;
                        &:focus-visible {
                            // color: $contrastWhite !important;
                            font-weight: bold;
                        }
                    }
                }
                .selected {
                    color:  $contrastSecondary;
                }
            }
        }
        .select-wrapper {
            &:focus-visible, &:focus-within {
                border-color: $contrastWhite !important;
            }
        }
    }
}