.checkbox-wrapper {
    padding-bottom: 10px;
    &.other-text {
        .accept {
            .check {
                margin-right: 5px;
            }
        }
    }
    .accept {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        &:focus-visible {
            p {
                border-color: $checkboxFocusColor;
            }
        }
        .check {
            min-width: 22px;
            width: 22px;
            height: 22px;
            border: 2px solid $checkboxBorderColor;
            cursor: pointer;
            margin-right: 25px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                width: 12px;
                height: 9px;
                fill: $checkboxFontColor;
            }

            &.error {
                border-color: $error;
            }
        }
        p {
            color: $checkboxFontColor;
            width: max-content;
            border: 2px solid transparent;
        }
    }
}

.radio-wrapper {
    margin-bottom: 15px;
    &:last-of-type {
        margin-bottom: 0;
    }
    label {
        padding-left: 10px;
        color: $checkboxFontColor;
    }
    input {
        transform: scale(1.6); 
    }
}

.contrast-wcag {
    .checkbox-wrapper {
        .accept {
            &:focus-visible {
                p {
                    border-color: $contrastWhite;
                }
            }
            .check {
                border-color: $contrastWhite;
                svg {
                    fill: $contrastWhite;
                }
            }
            p {
                color: $contrastPrimary;
            }
        }
    }
}