.sections-box {
    border: 1px solid $secondaryColor;
    .sections-component {
        padding: 10px;
        .section {
            border: 1px solid $secondaryColor;
            padding: 50px 30px 20px 30px;
            position: relative;
            margin-bottom: 15px;
            background-color: #ffffff;
            @media( max-width: 600px ) {
                padding: 40px 15px 20px 15px;
            }
            @media( max-width: 400px ) {
                padding: 40px 5px 20px 5px;
            }
            .section-index {
                display: flex;
                align-items: center;
                position: absolute;
                right: 0;
                top: 10px;
                padding: 0 30px;
                @media( max-width: 600px ) {
                    top: 5px;
                    right: 0;
                    padding: 0 15px;
                }
                @media( max-width: 400px ) {
                    padding: 0 5px;
                }
                i, span {
                    color: $secondaryColor;
                    border: 2px solid transparent;
                    &.remove-item-icon {
                        cursor: pointer;
                    }
                    &:focus-visible {
                        border-color: $focusColor;
                    }
                }
                span {
                    margin-right: 5px;
                    font-weight: bold;
                    cursor: pointer;
                }
                .svg-icon {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    margin-right: 5px;
                }
            }
            .content {
                background-color: #ffffff;
                display: none;
                &.active {
                    display: block;
                }
            }
            .row-section {
                margin-bottom: 15px;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
    .add-component {
        display: flex;
        .sections-list {
            display: flex;
            flex-wrap: wrap;
            padding: 10px;
            .item-clicked {
                position: relative;
                // &:nth-of-type(2n) {
                //     margin: 0 15px;
                // }
                margin: 10px;
                border: 2px solid transparent;
                &:focus-visible {
                    border-color: $btnFocusColor;
                }
                li {
                    display: flex;
                    align-items: center;
                    padding: 14.5px;
                    text-align: center;
                    background-color: $btnSecondaryBg;
                    color: $btnSecondaryFont;
                    cursor: pointer;
                    font-weight: bold;
                    transition: .3s;
                    border-radius: 30px;
                    p {
                        padding-right: 25px;
                    }
                    &:hover {
                        background-color: $btnSecondaryBgHover;
                        color: $btnSecondaryFontHover;
                        &+ .svg-icon {
                            svg {
                                fill: $btnSecondaryFontHover;
                            }
                        }
                    }
                }
                .svg-icon {
                    position: absolute;
                    top: calc(50% - 10px);
                    right: 15px;
                    svg {
                        fill: $btnSecondaryFont;
                        z-index: 10;
                        cursor: pointer;
                        transition: .3s;
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }
    .image-popup, .media-popup {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999999;
        background: rgba(36, 35, 35, 0.7);
        width: 100%;
        height: 100%;
        .image-src {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-height: 100vh;
        }
        .close {
            position: absolute;
            top: 10px;
            right: 10px;
            color: #ffffff;
            z-index: 99999999;
            cursor: pointer;
            font-weight: bold;
            font-size: 18px;
            padding: 10px;
            background: #000000;
            border: 1px solid #ffffff;
        }
    }
}

.add-item-in-section {
    margin-top: 20px;
}

.contrast-wcag {
    .sections-box {
        border-color: $contrastWhite;
        .sections-component {
            .section {
                background-color: $contrastSecondary;
                border-color: $contrastWhite;
                .section-index {
                    i, span {
                        color: $contrastPrimary;
                        &:focus-visible {
                            border-color: $contrastWhite;
                        }
                    }
                }
            }
        }
        .add-component {
            .sections-list {
                .item-clicked {
                    &:focus-visible {
                        border-color: $contrastWhite;
                    }
                    li {
                        p {
                            color: $contrastSecondary;
                        }
                        &:hover {
                            background-color: $contrastWhite;
                            &+.svg-icon {
                                svg {
                                    fill: $contrastSecondary;
                                }
                            }
                        }
                    }
                    .svg-icon {
                        svg {
                            fill: $contrastSecondary;
                        }
                    }
                }
            }
        }
    }
}

.contrast-wcag {
    .sections-box {
        .sections-component {
            .section {
                .content {
                    background-color: $contrastSecondary;
                }
            }
        }
    }
}
